import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ComponentesGeneral_01 from "../images/ComponentesGeneral_01.png";
import ComponentesGeneral_02 from "../images/ComponentesGeneral_02.png";
import ComponentesGeneral_03 from "../images/ComponentesGeneral_03.png";
import FindInPageIcon from '@mui/icons-material/FindInPage';

const ComponentesGeneral = () => {
    const [mostrarCompleto, setMostrarCompleto] = useState(false);

    const handleClick = () => {
        setMostrarCompleto(!mostrarCompleto);
    };

    const textoCompleto = (
        <>

            Descubre la excelencia en cada detalle con los Componentes de Motobombas, C.A. Nuestra extensa gama de componentes esenciales para sistemas de bombeo y motores garantiza un rendimiento óptimo y una instalación segura. Desde elementos de conexión hasta accesorios especializados, cada componente se selecciona cuidadosamente para ofrecer durabilidad, eficiencia y confiabilidad en cada aplicación. En Motobombas, nos enorgullece proporcionar soluciones integrales que impulsan el éxito de tus proyectos. Con nuestra calidad excepcional y compromiso inquebrantable, confía en nosotros para superar tus expectativas y ofrecerte lo mejor en componentes para tus sistemas eléctricos y mecánicos. Elevamos la eficiencia de tus operaciones, un componente a la vez.
        </>
    );

    const textoTruncado = (
        <>
            Descubre la excelencia en cada detalle con los Componentes de Mot...
        </>
    );

    return (
        <Container fluid>
            <Row className="justify-content-center mt-4">
                <Col xs={12} md={2} className="mb-4">
                    <Card border="light">
                        <Carousel>
                            <Carousel.Item>
                                <img className="d-block w-100" src={ComponentesGeneral_01} alt="Imagen 1" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={ComponentesGeneral_02} alt="Imagen 2" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={ComponentesGeneral_03} alt="Imagen 2" />
                            </Carousel.Item>
                        </Carousel>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Componentes</Card.Title>
                            <Card.Text>
                                {mostrarCompleto ? textoCompleto : textoTruncado}
                                <span style={{ color: 'blue', cursor: 'pointer' }} onClick={handleClick}>
                                    {mostrarCompleto ? ' Mostrar menos' : ' Mostrar más'}
                                </span>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Mas información</Card.Title>
                            <Card.Text>
                            <Button variant="light" className="m-2"><Link to="/ComponentesGeneral"><FindInPageIcon fontSize='large' color='primary' /></Link></Button>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default ComponentesGeneral;