import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MotoresSumergibles_01 from "../images/MotoresSumergibles_01.png";
import MotoresSumergibles_02 from "../images/MotoresSumergibles_02.png";
import MotoresSumergibles_03 from "../images/MotoresSumergibles_03.png";
import FindInPageIcon from '@mui/icons-material/FindInPage';

const MotoresSumergibles = () => {
    const [mostrarCompleto, setMostrarCompleto] = useState(false);

    const handleClick = () => {
        setMostrarCompleto(!mostrarCompleto);
    };

    const textoCompleto = (
        <>

            Descubre la innovación y la eficiencia de los Motores Sumergibles de Motobombas, C.A., diseñados para proporcionar un rendimiento excepcional en aplicaciones de bombeo sumergido. Nuestra línea de motores sumergibles destaca por su fiabilidad y resistencia, ofreciendo soluciones potentes para satisfacer diversas necesidades en entornos acuáticos. Equipados con tecnología de punta, estos motores garantizan un funcionamiento estable y duradero, desde aplicaciones residenciales hasta proyectos industriales complejos. Sumérgete en la confianza de los Motores Sumergibles de Motobombas, respaldados por décadas de experiencia y liderazgo en el sector de equipos de bombeo.
        </>
    );

    const textoTruncado = (
        <>
            Descubre la innovación y la eficiencia de los Motores Sumergibles...
        </>
    );

    return (
        <Container fluid>
            <Row className="justify-content-center mt-4">
                <Col xs={12} md={2} className="mb-4">
                    <Card border="light">
                        <Carousel>
                            <Carousel.Item>
                                <img className="d-block w-100" src={MotoresSumergibles_01} alt="Imagen 1" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={MotoresSumergibles_02} alt="Imagen 2" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={MotoresSumergibles_03} alt="Imagen 2" />
                            </Carousel.Item>
                        </Carousel>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Motores Sumergibles</Card.Title>
                            <Card.Text>
                                {mostrarCompleto ? textoCompleto : textoTruncado}
                                <span style={{ color: 'blue', cursor: 'pointer' }} onClick={handleClick}>
                                    {mostrarCompleto ? ' Mostrar menos' : ' Mostrar más'}
                                </span>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Mas información</Card.Title>
                            <Card.Text>
                            <Button variant="light" className="m-2"><Link to="/MotoresSumergibles"><FindInPageIcon fontSize='large' color='primary' /></Link></Button>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default MotoresSumergibles;