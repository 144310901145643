import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ComponentesEléctricos_01 from "../images/ComponentesEléctricos_01.png";
import ComponentesEléctricos_02 from "../images/ComponentesEléctricos_02.png";
import ComponentesEléctricos_03 from "../images/ComponentesEléctricos_03.png";
import FindInPageIcon from '@mui/icons-material/FindInPage';

const ComponentesEléctricos = () => {
    const [mostrarCompleto, setMostrarCompleto] = useState(false);

    const handleClick = () => {
        setMostrarCompleto(!mostrarCompleto);
    };

    const textoCompleto = (
        <>

            Explora la innovación y la confiabilidad con los Componentes Eléctricos de Motobombas, C.A. Diseñados para brindar soluciones seguras y eficientes en tus instalaciones de bombas y motores, nuestros componentes eléctricos garantizan un rendimiento óptimo. Desde interruptores hasta conectores, cada componente se selecciona cuidadosamente para ofrecer la máxima durabilidad y seguridad en tus sistemas eléctricos. Con Motobombas, confía en la calidad de nuestros componentes eléctricos para asegurar una operación sin problemas y un rendimiento excepcional en cada aplicación. Elevamos tus expectativas, proporcionando la energía necesaria para impulsar tus proyectos con éxito.
        </>
    );

    const textoTruncado = (
        <>
            Explora la innovación y la confiabilidad con los Componentes Elé...
        </>
    );

    return (
        <Container fluid>
            <Row className="justify-content-center mt-4">
                <Col xs={12} md={2} className="mb-4">
                    <Card border="light">
                        <Carousel>
                            <Carousel.Item>
                                <img className="d-block w-100" src={ComponentesEléctricos_01} alt="Imagen 1" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={ComponentesEléctricos_02} alt="Imagen 2" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={ComponentesEléctricos_03} alt="Imagen 2" />
                            </Carousel.Item>
                        </Carousel>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Componentes Eléctricos</Card.Title>
                            <Card.Text>
                                {mostrarCompleto ? textoCompleto : textoTruncado}
                                <span style={{ color: 'blue', cursor: 'pointer' }} onClick={handleClick}>
                                    {mostrarCompleto ? ' Mostrar menos' : ' Mostrar más'}
                                </span>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Mas información</Card.Title>
                            <Card.Text>
                            <Button variant="light" className="m-2"><Link to="/ComponentesEléctricos"><FindInPageIcon fontSize='large' color='primary' /></Link></Button>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default ComponentesEléctricos;
