import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Bombas from "./pages/Bombas";
import Cables from "./pages/Cables";
import Componentes from "./pages/Componentes";
import Motores from "./pages/Motores";
import Tubos from "./pages/Tubos";
import Contacto from "./pages/Contacto";
import AboutUs from "./pages/AboutUs";
import BombasVerticalesTipoTurbinaPag from "./pages/BombasVerticalesTipoTurbinaPag";
import Serie6900Faboven from "./pages/Serie6900Faboven";
import Serie7000Faboven from "./pages/Serie7000Faboven";
import BombasHorizontalesdeSuperficiePag from "./pages/BombasHorizontalesdeSuperficiePag";
import BombassumergiblesdepozoPag from "./pages/BombasSumergiblesdePozoPag";
import Serie95PRPanelli from "./pages/Serie95PRPanelli";
import Serie95SXRECPanelli from "./pages/Serie95SXRECPanelli";
import SerieTSAFaboven from "./pages/SerieTSAFaboven";
import Serie140PRPanelli from "./pages/Serie140PRPanelli";
import Serie140SXRECPanelli from "./pages/Serie140SXRECPanelli";
import Serie180SXRECPanelli from "./pages/Serie180SXRECPanelli";
import BombasSumergiblesdeAguasServidasPag from "./pages/BombasSumergiblesdeAguasServidasPag";
import CablesPlanosSumergiblesPag from "./pages/CablesPlanosSumergiblesPag";
import ComponentesGeneralPag from "./pages/ComponentesGeneralPag";
import ComponentesEléctricosPag from "./pages/ComponentesEléctricosPag";
import MotoresVerticalesEjeHuecoVHSPag from "./pages/MotoresVerticalesEjeHuecoVHSPag";
import MotoresHorizontalesPag from "./pages/MotoresHorizontalesPag";
import MotoresSumergiblesPag from "./pages/MotoresSumergiblesPag";
import TubosColumnasdeAceroalCarbonoPag from "./pages/TubosColumnasdeAceroalCarbonoPag";
import TubosColumnasdeUPVCPag from "./pages/TubosColumnasdeUPVCPag";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="bombas" element={<Bombas />} />
          <Route path="BombasVerticalesTipoTurbinaPag" element={<BombasVerticalesTipoTurbinaPag />} />
          <Route path="Serie6900Faboven" element={<Serie6900Faboven />} />
          <Route path="Serie7000Faboven" element={<Serie7000Faboven />} />
          <Route path="BombasHorizontalesdeSuperficiePag" element={<BombasHorizontalesdeSuperficiePag />} />
          <Route path="BombassumergiblesdepozoPag" element={<BombassumergiblesdepozoPag />} />
          <Route path="Serie95PRPanelli" element={<Serie95PRPanelli />} />
          <Route path="Serie95SXRECPanelli" element={<Serie95SXRECPanelli />} />
          <Route path="SerieTSAFaboven" element={<SerieTSAFaboven />} />
          <Route path="Serie140PRPanelli" element={<Serie140PRPanelli />} />
          <Route path="Serie140SXRECPanelli" element={<Serie140SXRECPanelli />} />
          <Route path="Serie180SXRECPanelli" element={<Serie180SXRECPanelli />} />
          <Route path="BombasSumergiblesdeAguasServidasPag" element={<BombasSumergiblesdeAguasServidasPag />} />
          <Route path="cables" element={<Cables />} />
          <Route path="CablesPlanosSumergiblesPag" element={<CablesPlanosSumergiblesPag />} />
          <Route path="componentes" element={<Componentes />} />
          <Route path="ComponentesGeneralPag" element={<ComponentesGeneralPag />} />
          <Route path="ComponentesEléctricosPag" element={<ComponentesEléctricosPag />} />
          <Route path="motores" element={<Motores />} />
          <Route path="MotoresVerticalesEjeHuecoVHSPag" element={<MotoresVerticalesEjeHuecoVHSPag />} />
          <Route path="MotoresHorizontalesPag" element={<MotoresHorizontalesPag />} />
          <Route path="MotoresSumergiblesPag" element={<MotoresSumergiblesPag />} />
          <Route path="tubos" element={<Tubos />} />
          <Route path="TubosColumnasdeAceroalCarbonoPag" element={<TubosColumnasdeAceroalCarbonoPag />} />
          <Route path="TubosColumnasdeUPVCPag" element={<TubosColumnasdeUPVCPag />} />
          <Route path="contacto" element={<Contacto />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
